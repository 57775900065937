/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "여덟 번째 에피소드에서는 장애 리포트, AWS / KT 장애 후속 조치, Notion.so 가이드, 앱 구독 모델에 대해서 이야기를 했습니다."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://sreweekly.com/"
  }, "SRE WEEKLY – scalability, availability, incident response, automation")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://outage.report/"
  }, "Outage.Report")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://news.sbs.co.kr/news/endPage.do?news_id=N1005048898&plink=THUMB&cooper=SBSNEWSPROGRAM"
  }, "11개국 초유의 '동시 먹통'…'인증서 만료'가 부른 대란")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.theregister.co.uk/2018/12/06/ericsson_o2_telefonica_uk_outage/"
  }, "Why millions of Brits' mobile phones were knackered on Thursday: An expired Ericsson software certificate • The Register")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://letsencrypt.org/"
  }, "Let's Encrypt - Free SSL/TLS Certificates")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/certificate-manager/"
  }, "AWS Certificate Manager - Amazon Web Services(AWS)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.toss.im/"
  }, "Toss Feed - 토스 공식 블로그")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.toss.im/2018/12/10/newsroom/press-release/toss-80-million-funding/"
  }, "토스, 8천만 달러 투자 유치하며 기업가치 1조 3천억 원 인정받아")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/blogs/korea/follow-up-to-the-november-22-event-in-aws-seoul-region/"
  }, "AWS 장애 후속 조치")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://m.check.kt.com/main/s_KtCompensation.do"
  }, "KT - 서비스 장애 보상 대상 조회")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.ondemandkorea.com/"
  }, "OnDemandKorea")), "\n"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.google.co.kr/search?hl=ko&q=%EC%A0%9C%EC%A3%BC%ED%95%AD%EA%B3%B5&lr=lang_ko"
  }, "제주항공 - Google 검색")), "\n"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.notion.so/haebom/Notion-1ad7ccbc41a44298814a4820d4acb14e"
  }, "노션 가이드 한국어 번역본")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.notion.so/daangn/07ca1fda22584d60a48ef43a8cf9bab0"
  }, "당근마켓과 함께 할 개발자를 찾고 있어요!")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://wordpress.org/gutenberg/"
  }, "The new Gutenberg editing experience")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://dayoneapp.com/publish/"
  }, "Publish | Day One")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://macnews.tistory.com/2494"
  }, "스크리브너(Scrivener) 한국어 튜토리얼 가이드 개정판 :: Back to the Mac 블로그")), "\n"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://bear.app/"
  }, "Bear - Notes for iPhone, iPad and Mac")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ulysses.app/"
  }, "Ulysses")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://feedly.com/i/welcome"
  }, "Feedly")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.instapaper.com/"
  }, "Instapaper")), "\n"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://m.blog.naver.com/PostView.nhn?blogId=super_jjeong&logNo=221392307269&targetKeyword=&targetRecommendationCode=1"
  }, "스타트업 문서도구 'Notion'으로 이전한 후기 : 네이버 블로그")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.notion.so/hellonewworld/Notion-1-a842647363f34de38763b42fa28d0178"
  }, "Notion 1년간의 사용기")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.joins.com/"
  }, "디지털 구독의 시작, 조인스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://events.linuxfoundation.org/events/kubecon-cloudnativecon-north-america-2018/"
  }, "KubeCon + CloudNativeCon North America 2018 - Linux Foundation Events")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=lcygvgW6sFM"
  }, "The Illustrated Children's Guide to Kubernetes - YouTube")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
